import { makeStyles } from "@material-ui/core/styles"

interface ManagementProps {
  backgroundImage: string
}

export const useStyles = makeStyles((theme) => ({
  root: ({ backgroundImage }: ManagementProps) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  }),
  innerWrapper: {
    maxWidth: 1440,
  },
  leftSide: {
    padding: "0 60px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px !important",
    },
  },
  rightSide: {
    padding: "0 60px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px !important",
    },
  },
  logo: {
    width: "200px",
    padding: "20px 0"
  },
  deviceImage: {
    width: "100%",
  },
}))
