import React, { useState, useRef } from "react"
import emailjs from "emailjs-com"
import { Form } from "react-final-form"
import {
  TextField,
  Checkboxes,
  Radios,
  Select,
  DatePicker,
  TimePicker,
} from "mui-rff"
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
} from "@material-ui/core"

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = "Required"
  }
  if (!values.lastName) {
    errors.lastName = "Required"
  }
  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }
  if (!values.phone) {
    errors.phone = "Required"
  }
  return errors
}

const formFields = [
  {
    size: 6,
    field: (
      <TextField
        label="First Name"
        name="firstName"
        variant="outlined"
        size="small"
        margin="none"
        required={true}
      />
    ),
  },
  {
    size: 6,
    field: (
      <TextField
        label="Last Name"
        name="lastName"
        variant="outlined"
        size="small"
        margin="none"
        required={true}
      />
    ),
  },
  {
    size: 6,
    field: (
      <TextField
        type="email"
        label="Email"
        name="email"
        variant="outlined"
        size="small"
        margin="none"
        required={true}
      />
    ),
  },
  {
    size: 6,
    field: (
      <TextField
        label="Phone Number"
        name="phone"
        variant="outlined"
        size="small"
        margin="none"
        required={true}
      />
    ),
  },
  {
    size: 12,
    field: (
      <Checkboxes
        label="I'm most interested in:"
        name="interests"
        size="small"
        formControlProps={{ margin: "none" }}
        formGroupProps={{ row: true }}
        data={[
          { label: "Managed data services", value: "Managed data services " },
          {
            label: "Strategic advisory and organizational planning",
            value: "Strategic advisory and organizational planning ",
          },
          {
            label: "Vendor evaluation and selection",
            value: "Vendor evaluation and selection ",
          },
          { label: "Process optimization", value: "Process optimization " },
          {
            label: "Technology implementation and integration",
            value: "Technology implementation and integration ",
          },
          {
            label: "Data quality and governance",
            value: "Data quality and governance ",
          },
          {
            label: "Analytics and reporting",
            value: "Analytics and reporting ",
          },
        ]}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        name="message"
        variant="outlined"
        size="small"
        multiline
        rows={2}
        label="How can we help you?"
        margin="none"
      />
    ),
  },
]
import { useStyles } from "./styles"

const title = "Let's Get Started"
const description =
  "I'd like to learn more about how Grandview can improve our technology, processes, and insights."

const GetStarted = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSubmitFailure, setFormSubmitFailure] = useState(false)

  const onSubmit = values => {
    emailjs
      .send(
        "gmail",
        "gv_web_contact_form",
        values,
        "user_HKhnp4kXUd0KSLAxWNuzP"
      )
      .then(
        result => {
          console.log(result)
          setFormSubmitted(true)
        },
        error => {
          console.log(error)
          setFormSubmitFailure(true)
        }
      )
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container className={classes.innerWrapper}>
        {!formSubmitted && (
          <>
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.formWrapper}
        >
          <div style={{ padding: 10, margin: "auto", maxWidth: 800 }}>
            {!formSubmitted && (
              <Form
                onSubmit={onSubmit}
                initialValues={{}}
                validate={validate}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    {/* <Paper style={{ padding: 16 }}> */}
                    <Grid container alignItems="flex-start" spacing={6}>
                      {formFields.map((item, idx) => (
                        <Grid item xs={item.size} key={idx}>
                          {item.field}
                        </Grid>
                      ))}
                      <Grid item style={{ marginTop: 16 }} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={submitting}
                          className={classes.submitButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    {/* </Paper> */}
                  </form>
                )}
              />
            )}
            {formSubmitted && (
              <div className={classes.submittedMessage}>
                <Typography variant="h5">
                  Thank you for your interest in Grandview!
                </Typography>
                <Typography variant="subtitle2">
                  We will contact you shortly to discuss your needs and how we
                  can help.
                </Typography>
              </div>
            )}
            {formSubmitFailure && (
              <div className={classes.submittedMessage}>
                <Typography variant="h5">
                  There was an error with your form submission.
                </Typography>
                <br />
                <Typography variant="h6">
                  You may contact us directly at: info@grandviewanalytics.com
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default GetStarted
