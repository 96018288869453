import * as React from "react"
import { Grid } from "@material-ui/core"

import SectionHeader from "../../../components/SectionHeader"
import SectionText from "../../../components/SectionText"
import LearnMoreButton from "../../../components/LearnMoreButton"
import backgroundImageUrl from "../../../../media/home/management-background.jpg"
import devicesImageUrl from "../../../../media/home/devices.png"
import rivvitLogo from "../../../../media/rivvit-logo.png"
import { useStyles } from "./styles"

const title = `Managed Data and Reporting Platform`
const paragraph = "Focus on your business and leave data management and reporting to Grandview. With our proprietary managed data and reporting platform, Rivvit, we deliver high-quality data with customized reports, dashboards, analytics, and workflow applications in a fraction of the time for a predictable subscription fee."

const Management = () => {
  const classes = useStyles({ backgroundImage: backgroundImageUrl })

  return (
    <div className={classes.root}>
      <Grid container spacing={10} className={classes.innerWrapper}>
        <Grid item key={`management-item-left`} xs={12} sm={12} md={6} className={classes.leftSide}>
          <img src={devicesImageUrl} className={classes.deviceImage} />
        </Grid>
        <Grid item key={`management-item-right`} xs={12} sm={12} md={6} className={classes.rightSide}>
          <img src={rivvitLogo} alt="Rivvit" className={classes.logo}/>
          <SectionHeader text={title} align="left" />
          <SectionText text={paragraph} align="left" />
          <LearnMoreButton to="technology" />          
        </Grid>
      </Grid>
    </div>
  )
}

export default Management
