import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: 15,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 20
  },
  iconWrapper: {
    height: 110,
    marginBottom: 20,
  },
  icon: {
    width: 100,
    height: 100,
  },
  title: {
    maxWidth: 280,
    fontSize: "1.2rem",
    lineHeight: 1.3,
    textAlign: "center",
  },
}))
