import * as React from "react"

import LearnMoreButton from "../../../components/LearnMoreButton"
import Technologies from "../Technologies"
import Introduction from "../../../components/Introduction"

import { useStyles } from "./styles"

const title = "Modernizing technology for financial institutions"
const paragraph1 = "Grandview helps financial institutions modernize data and technology environments, giving them the tools and information they need to accelerate decision making, optimize workflows, and mitigate risk."
const paragraph2 = "With decades of financial industry experience, we deliver strategic advisory, technology implementation, systems integration, and data management services for a broad range of financial institutions. Our clients include investment managers, insurance companies, private equity firms, hedge funds, family offices, direct lenders, fund administrators, and banks."
const para = [
  paragraph1,
  paragraph2
]
const FinancialInstituions = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Introduction
          title={title}
          introduction={para}
        />
        <Technologies />
        <LearnMoreButton to="services" />
      </div>
    </div>
  )
}

export default FinancialInstituions
