import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"

import homeHeroBgUrl from "../../media/home-banner-overlay.jpg"
import FinancialInstituions from "../sections/home/FinancialInstitutions"
import ExploreServices from "../sections/home/ExploreServices"
import Implementations from "../sections/home/Implementations"
import Management from "../sections/home/Management"
import WhyGrandview from "../sections/home/WhyGrandview"
import GetStarted from "../sections/home/GetStarted"

const homeHeroTitle = "Technology consulting and managed data services"
const homeHeroDescription = "Helping financial institutions align people, process, data, and technology to manage and grow their businesses"

const IndexPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Hero
      home={true}
      backgroundImage={homeHeroBgUrl}
      title={homeHeroTitle}
      description={homeHeroDescription}
    />
    <FinancialInstituions />
    <ExploreServices />
    <Implementations />
    <Management />
    <WhyGrandview />
    <GetStarted />
  </Layout>
)

export default IndexPage
