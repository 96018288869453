import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    maxWidth: 1440,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
  },
  itemTitleWrapper: {
    maxWidth: 200,
  },
  itemTitle: {
    //color: "#2a4ca5",
    fontSize: "1.6rem",
    textAlign: "center",
    marginBottom: 10,
    [theme.breakpoints.up("lg")]: {
      //maxWidth: 310,
    },
  },
  itemBody: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemText: {
    color: "#000",
    marginBottom: 30,
    textAlign: "left",
    
  },
  itemLink: {
    color: "#fff",
    fontFamily: "Roboto Regular",
    fontSize: "1.75rem",
    textTransform: "capitalize",
    textDecoration: "none",
    width: "100%",
    marginTop: 10,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
}))
