import { makeStyles } from "@material-ui/core/styles"

export const userStyles = makeStyles(() => ({
  root: ({ isDark, align }: any) => ({
    color: isDark ? "#fff" : "#000",
    textAlign: align ? align: "center",
    marginBottom: 20,
    fontSize: "2rem",
    lineHeight: "2.4rem"
  }),
}))
