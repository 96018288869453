import React from "react"
import { Grid, Typography } from "@material-ui/core"

import { technologyList } from "./data"
import { useStyles } from "./styles"

const Technologies = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
      {technologyList.map((item, index) => (
        <Grid item key={`technology-item-${index}`} xs={12} sm={6} md={6} lg={3} xl={3} className={classes.item}>
          <div className={classes.iconWrapper}>
            <img src={item.icon} className={classes.icon} />
          </div>
          <Typography variant="subtitle2" className={classes.title}>
            {item.title}
          </Typography>
        </Grid>
      ))}
      </Grid>
    </div>
  )
}

export default Technologies
