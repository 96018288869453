import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    background: "#2697db",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  triangle: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "30%",
  },
  innerWrapper: {
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}))
