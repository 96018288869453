import * as React from "react"

import SectionHeader from "../../../components/SectionHeader"
import SectionText from "../../../components/SectionText"
import { useStyles } from "./styles"

import triangleImgUrl from "../../../../media/blue-triangle-large.png"

const title = "Explore Our Services"
const paragraph = "Whether implementing a full life cycle portfolio management solution or integrating data across systems, our services improve operations and put critical data in the hands of analysts, traders, portfolio managers, and key business stakeholders."

const ExploreServices = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={triangleImgUrl} className={classes.triangle} />
      <div className={classes.innerWrapper}>
        <SectionHeader isDark text={title} />
        <SectionText isDark text={paragraph} />
      </div>
    </div>
  )
}

export default ExploreServices
