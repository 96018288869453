import * as React from "react"
import { Link } from "gatsby"
import { Grid, Typography, Button } from "@material-ui/core"

import SectionHeader from "../../../components/SectionHeader"
import { whyList } from "./data"
import { useStyles } from "./styles"

const title = "Why Grandview"

const WhyGrandview = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <SectionHeader text={title} />
        <Grid container spacing={10}>
        {whyList.map((item, index) => (
          <Grid item key={`why-item-${index}`} xs={12} sm={12} md={12} lg={4} className={classes.item}>
            <Typography variant="subtitle2" className={classes.itemTitle}>
              {item.title}
            </Typography>
            <div className={classes.itemBody}>
              <Typography variant="body2" className={classes.itemText}>
                {item.description}
              </Typography>
              <Button variant="contained" color="primary" href={item.link} className={classes.itemLink}>{item.linkText}</Button>
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default WhyGrandview
