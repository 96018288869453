import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  innerWrapper: {
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
}))

