import improveBusinessIcon from "../../../../media/home/improve-business-icon.png"
import deployIcon from "../../../../media/home/deploy-icon.png"
import singleSourceIcon from "../../../../media/home/single-source-icon.png"
import dashboardReportIcon from "../../../../media/home/dashboard-report-icon.png"

export const technologyList = [
  {
    icon: improveBusinessIcon,
    title: "Improve business processes and workflows"
  },
  {
    icon: deployIcon,
    title: "Deploy and integrate complex technologies"
  },
  {
    icon: singleSourceIcon,
    title: "Achieve a single source of truth for enterprise data"
  },
  {
    icon: dashboardReportIcon,
    title: "Generate insightful dashboards and reports"
  }
]
