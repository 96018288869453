import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  '@global': {
    '.MuiFormControlLabel-root':{
      width:"100%"
    },
    '.MuiFormControlLabel-label': {
      fontSize: '1rem',
      lineHeight: '1.2rem'
    },
    '.MuiFormGroup-row': {
      marginTop: 10
    }
  },
  innerWrapper: {
    //width: "100%",
    maxWidth: 1440,
    //display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#f5f7f6",
    border: "1px solid #b4b4b4",
    padding: "40px 0",
  },
  title: {
    //color: "#2a4ca5",
    fontSize:"2rem",
    textAlign: "center",
  },
  description: {
    color: "#000",
    textAlign: "center",
    fontSize: "1rem",
    margin: 20,
  },
  formWrapper: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  input: {
    width: "100%",
    margin: 10,
    backgroundColor: "#fff",
  },
  optionTitle: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    color: "#000",
    fontFamily: "Roboto Regular",
    fontSize: "1.75rem",
    lineHeight: 1.2,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  optionGroup: {
    width: "100%",
    marginBottom: 30,
    "& > label > span": {
      fontSize: "1.25rem"
    }
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 20,
  },
  submitButton: {
    minWidth: 300,
    width: "100%",
    color: "#fff",
    fontSize: 24,
  },
}))
