export const whyList = [
  {
    title: "Dedicated financial industry focus",
    description: "We focus exclusively on the financial services industry. We have deep domain expertise across asset classes, business functions, and investment vehicle types.",
    linkText: "Meet our team",
    link: "/about-us"    
  },
  {
    title: "Connecting business and technology",
    description: "With decades of experience working in business and technology roles for financial institutions, we are uniquely positioned to anticipate issues and risks, and deliver solutions faster.",
    linkText: "Discover our services",
    link: "/services"
  },
  {
    title: "Client centered approach",
    description: "Our clients rely on us as a trusted advisor because we are committed to long-term, mutually beneficial partnerships. With utmost integrity, we always do what's best for the client.",
    linkText: "View client success stories",
    link: "/client-work"
  }
]
