import React from "react"
import { Typography } from "@material-ui/core"

import { userStyles } from "./styles"

interface SectionHeaderProps {
  isDark?: boolean,
  text: string,
  align?: string
}
const SectionHeader = ({ isDark, text, align = "center" }: SectionHeaderProps) => {
  const classes = userStyles({ isDark, text, align })

  return (
    <Typography variant="h4" className={classes.root}>
      {text}
    </Typography>
  )
}

export default SectionHeader
