import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: ({ isDark, align }: any) => ({
    color: isDark ? "#fff" : "#4d4d4d",
    lineHeight: 1.6,
    fontSize: "1.2rem",
    textAlign: align ? align : "center",
    marginBottom: 0,
  }),
}))
