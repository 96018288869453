import React from "react"
import PropTypes from "prop-types"

import { useStyles } from "./styles"
import { Typography } from "@material-ui/core"

interface SectionTextProps {
  isDark?: boolean,
  text: string,
  align?: string
}

const SectionText = ({ isDark, text, align="center" }: SectionTextProps) => {
  const classes = useStyles({ isDark, text, align })

  return (
    <Typography variant="body2" className={classes.root}>
      {text}
    </Typography>
  )
}

export default SectionText
